<template>
  <div>
    <template v-if="!loading">
      <v-layout row align-center>
        <backButton />
        <h2 class="mb-4">{{ page.Name[currentLanguage] }}</h2>
      </v-layout>

      <template v-if="page">
        <edit-page-details-settings :page="page" />
        <edit-page-details-seo
          :pageDescriptions="pageDescriptions"
          :page="page"
          :pageDefaultLanguageProp="pageDefaultLanguage"
          :pageDefaultDescriptionProp="pageDefaultDescription"
          :pageLanguages="pageLanguages"
        />
        <edit-page-details-urlsettings
          :pageDefaultUrlProps="pageDefaultUrl"
          :pageUrlsProps="pageUrls"
          :page="page"
        />
        <edit-page-details-icon :loading="loading" :page="page" />
        <edit-page-details-linkedPages
          :pages="pages"
          :page="page"
          :websites="websites"
          :otherWebsitesProps="otherWebsites"
        />
      </template>
      <cancelConfirmButtons
        @goBack="goBack"
        :confirmFunction="savePage"
        :loading="saving"
        :confirmLabel="$t('Save')"
      />
    </template>
    <template v-else-if="!$store.getters.error">
      <preloader></preloader>
    </template>
  </div>
</template>

<script>
export default {
  data() {
    return {
      saving: false,
      page: null,
      pageLanguages: null,
      pageDefaultLanguage: null,
      pageDescriptions: null,
      pageDefaultDescription: null,
      pageUrls: null,
      pageDefaultUrl: null,
      pages: null,
      loading: false,
      websites: null,
      otherWebsites: null,

      icon: "",
    };
  },
  computed: {
    currentLanguage: {
      get: function () {
        return this.$i18n.locale();
      },
    },
  },
  created() {
    this.getWebsites();
    this.getUsers();

    if (!this.$route.params.id > 0) {
      let newPage = {
        Name: this.$helpers.createEmptyValueBasedOnType("text"),
        RepeatingContent: false,
        Enabled: false,
        EnableApi: false,
        Custom: false,
        CacheContent: false,
        WebsiteId: this.$route.params.websiteId,
        Title: this.$helpers.createEmptyValueBasedOnType("text"),
        Description: this.$helpers.createEmptyValueBasedOnType("text"),
        Url: this.$helpers.createEmptyValueBasedOnType("text"),
        UniqueName: "",
        SubPages: [],
        LinkedPages: [],
        Icon: "",
        Group: false,
      };

      newPage.Name[this.currentLanguage] = this.$route.params.name;

      this.pageLanguages = Object.keys(newPage.Title);
      this.pageDefaultLanguage = Object.keys(newPage.Title)[0];
      this.pageDescriptions = Object.keys(newPage.Description);
      this.pageDefaultDescription = Object.keys(newPage.Description)[0];
      this.pageUrls = Object.keys(newPage.Url);
      this.pageDefaultUrl = Object.keys(newPage.Url)[0];

      this.page = newPage;
      this.getPages(this.$route.params.websiteId.$oid);
    } else {
      this.getPage();
    }
  },

  methods: {
    goBack() {
      this.$router.go(-1);
    },
    getUsers() {
      this.$request.get("/users", null, (res) => {
        if (res.success) {
          this.users = res.data;
        } else {
          const toaster = {
            message: res.message,
            timeout: false,
            type: "ERROR",
            toastClassName: ["toaster-color-error", "toaster-layout"],
            bodyClassName: ["toaster-text-layout"],
            icon: "mdi-alert-octagon",
          };
          this.$store.dispatch("setToaster", toaster);
        }
      });
    },
    getPage() {
      this.loading = true;
      this.page == null;

      this.$request.get("/page/" + this.$route.params.id, null, (res) => {
        if (res.success) {
          if (res.data.Title == null) {
            res.data.Title = this.$helpers.createEmptyValueBasedOnType("text");
          }

          if (res.data.Description == null) {
            res.data.Description =
              this.$helpers.createEmptyValueBasedOnType("text");
          }

          if (res.data.Url == null) {
            res.data.Url = this.$helpers.createEmptyValueBasedOnType("text");
          }

          if (res.data.SynchronizationPutUrl == null) {
            res.data.SynchronizationPutUrl = "";
          }

          if (res.data.SynchronizationBulkPutUrl == null) {
            res.data.SynchronizationBulkPutUrl = "";
          }

          if (res.data.SynchronizationDeleteUrl == null) {
            res.data.SynchronizationDeleteUrl = "";
          }

          this.pageLanguages = Object.keys(res.data.Title);
          this.pageDefaultLanguage = Object.keys(res.data.Title)[0];
          this.pageDescriptions = Object.keys(res.data.Description);
          this.pageDefaultDescription = Object.keys(res.data.Description)[0];
          this.pageUrls = Object.keys(res.data.Url);
          this.pageDefaultUrl = Object.keys(res.data.Url)[0];

          if (res.data.LinkedPages == undefined) {
            res.data.LinkedPages = [];
          }
          this.page = res.data;
          this.$store.dispatch("setSelectedWebsiteById", this.page.WebsiteId);
          this.getPages(this.page.WebsiteId.$oid);
          this.setOtherWebsites();
        } else {
          const toaster = {
            message: res.message,
            timeout: false,
            type: "ERROR",
            toastClassName: ["toaster-color-error", "toaster-layout"],
            bodyClassName: ["toaster-text-layout"],
            icon: "mdi-alert-octagon",
          };
          this.$store.dispatch("setToaster", toaster);
        }
      });
    },
    savePage() {
      this.saving = true;
      this.$request.put("/page", this.page, (res) => {
        this.saving = false;

        if (res.success) {
          this.getWebsites();
          this.$router.go(-1);
        } else {
          const toaster = {
            message: res.message,
            timeout: false,
            type: "ERROR",
            toastClassName: ["toaster-color-error", "toaster-layout"],
            bodyClassName: ["toaster-text-layout"],
            icon: "mdi-alert-octagon",
          };
          this.$store.dispatch("setToaster", toaster);
        }
      });
    },
    getPages(websiteId) {
      if (websiteId) {
        this.loading = true;

        this.$request.get("/website/" + websiteId, null, (res) => {
          this.loading = false;

          if (res.success) {
            this.pages = res.data.Pages.sort(
              this.$helpers.sortByKey(this.currentLanguage)
            );
          } else {
            const toaster = {
              message: res.message,
              timeout: false,
              type: "ERROR",
              toastClassName: ["toaster-color-error", "toaster-layout"],
              bodyClassName: ["toaster-text-layout"],
              icon: "mdi-alert-octagon",
            };
            this.$store.dispatch("setToaster", toaster);
          }
        });
      }
    },
    getWebsites() {
      this.$request.get("/websites", null, (res) => {
        if (res.success) {
          this.$store.dispatch("setAllWebsites", res.data);
          this.websites = res.data;
          this.setOtherWebsites();
        } else {
          const toaster = {
            message: res.message,
            timeout: false,
            type: "ERROR",
            toastClassName: ["toaster-color-error", "toaster-layout"],
            bodyClassName: ["toaster-text-layout"],
            icon: "mdi-alert-octagon",
          };
          this.$store.dispatch("setToaster", toaster);
        }
      });
    },
    setOtherWebsites() {
      if (this.page && this.websites) {
        this.otherWebsites = this.websites.filter(
          (x) => x._id.$oid != this.page.WebsiteId.$oid
        );
      }
    },
  },
};
</script>


